.survey{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.SurveyPrompt{
	width: 77%;
	height: 10%;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 22px;
	align-items: center;
	text-align: center;
	display: flex;
}

.RadioSelection{
	display: flex;
	flex-direction: column;
	flex: 1;
}

.RadioSelection div{
	flex: 1;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
}
