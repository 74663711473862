@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');


* {
  box-sizing: border-box;
}

#root{
  width: 100%;
  height: 100%;
  margin: 0;
}

html{
  height: fit-content;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App{
  height: fit-content;
  width: 100%;
}

.Container{
  position: relative;
  width: 375px;
  height: 812px;
  margin: auto;
  background-color: white;
}

@media only screen and (max-width: 768px)  {
  .Container{
    width: 100%;
    height: 100%;
  }

  .App{
    height: 100%;
  }
}