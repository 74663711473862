.home{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.Title{
	margin-top: 18px;
	width: 85%;
}

.Title h1{
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 700;
	font-size: 22px;
	line-height: 24px;
	margin: 0px;
}

.Title h2{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
	margin: 0px;
}

.Hero{
	height: fit-content;
}

.Description{
	width: 85%;
}

.Description p{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
}

.CTA{
	margin-top: 30px;
	margin-bottom: 10px;
}