.thankyou{
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
}

.header{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 24px;
	text-align: center;
	width: 85%;
	margin-top: 38px;
}

.line{
	margin-top: 21px;
	margin-bottom: 28px;
}

.info{
	width: 77%;
	flex: 1;
}

.info p{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.info p.quote{
	font-family: 'Poppins';
	font-style: italic;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
}

.info a{
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	text-decoration-line: underline;
	color: #00A5BB;
}

.thankyou .Title{
	text-align: center;
}

.Logo{
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 16px;
	margin-bottom: 16px;
}

.LogoContainer{
	width: 50%;
}

.LogoContainer img{
	object-fit: contain;

}