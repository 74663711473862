button {
	all: unset;
	cursor: pointer;
      }

.btn{
	padding: 6px 30px;
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	border-radius: 4px;
	color: #FFFFFF;
	background-color: #17A2B8;
}

.btn.outlined{
	background: #FFFFFF;
	border: 1px solid #17A2B8;
	border-radius: 4px;
	color: #17A2B8;
}

button.btn:disabled{
	background: #808080;
	color: #ffffff;
}